import { Controller } from "@hotwired/stimulus";
import Chart from 'chart.js';

export default class ChartComponent extends Controller {
  static targets = ["canvas"];
  static values = {
    type: String,
    labels: Array,
    datasets: Array,
    hideLabels: Boolean
  };

  get optionsByType() {
    return {
      bar: this.barOptions,
      doughnut: this.doughnutOptions
    };
  }

  get doughnutOptions() {
    return {
      type: 'doughnut',
      data: {
        labels: this.labelsValue,
        datasets: this.datasetsValue,
      }
    };
  }

  get barOptions() {
    return {
      type: 'bar',
      data: {
        labels: this.labelsValue,
        datasets: this.datasetsValue
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              autoSkip: true

            },
          }],
          xAxes: [{
            ticks: {
              display: this.hideLabelsValue ? false : true,
              autoSkip: false
            },
          }],
        }
      }
    };
  }

  connect() {
    this.initChart();
  }

  initChart = () => {
    const options = this.optionsByType[this.typeValue];
    if (!options) throw "No options found for this Chart Type in Chart Controller!";

    new Chart(this.canvasTarget, options);
  };
}
