$(document).on('turbo:load', function () {
  $('.procedure-wizard-form #area').on('change', function () {
    let management_area_id = $(this).val();
    let process_type_selector = '#procedure_process_type_id';
    $.ajax({
      url: `/areas_gestion/${management_area_id}/tipos_de_tramite`,
      type: 'GET',
      data: {
        'management_area_id': management_area_id
      },
      dataType: 'json',
      success: function (data) {
        disable_field(process_type_selector);
        fill_process_types(data);
        enable_field(process_type_selector);
      },
      error: function (request, error) {
        console.error(error);
      }
    });
  });

  $('#procedure_promoter_id').on('change', function () {
    let promoter_id = $(this).val();
    get_unique_enviromental_keys(promoter_id);
    get_service_providers(promoter_id);
  });
});

global.get_service_providers = function (promoter_id) {
  let service_providers_selector = '#procedure_service_provider_id';
  $.ajax({
    url: `/service_providers`,
    type: 'GET',
    data: {
      'promoter_id': promoter_id
    },
    dataType: 'json',
    success: function (data) {
      disable_field(service_providers_selector);
      fill_service_providers(data);
      enable_field(service_providers_selector);
    },
    error: function (request, error) {
      console.error(error);
    }
  });
};

global.get_unique_enviromental_keys = function (promoter_id) {
  let unique_env_keys_selector = '#procedure_unique_enviromental_key_id';
  $.ajax({
    url: `/promoters/${promoter_id}/unique_enviromental_keys`,
    type: 'GET',
    data: {
      'promoter_id': promoter_id
    },
    dataType: 'json',
    success: function (data) {
      disable_field(unique_env_keys_selector);
      fill_unique_enviromental_keys(data);
      enable_field(unique_env_keys_selector);
    },
    error: function (request, error) {
      console.error(error);
    }
  });
};

global.fill_service_providers = function (data) {
  let select = $('#procedure_service_provider_id');
  select.find('option').remove();
  select.append('<option value="" selected>Selecciona un Prestador de Servicios</option>'); 
  $.each(data, function(key, value) {
    select.append(`<option value='${value.id}'>${value.name} (${value.rfc})</option>`);
  });
}

global.fill_unique_enviromental_keys = function (data) {
  let select = $('#procedure_unique_enviromental_key_id');
  select.find('option').remove();
  select.append('<option value="" selected>Selecciona una Clave Ambiental Única</option>');
  $.each(data, function (key, value) {
    select.append(`<option value='${value.id}'>${value.location.name} (${value.key})</option>`);
  });
};

global.fill_process_types = function (data) {
  let select = $('#procedure_process_type_id');
  select.find('option').remove();
  select.append('<option value="">Selecciona un Trámite</option>');
  $.each(data, function (key, value) {
    select.append(`<option value='${value.id}'>${value.name}</option>`);
  });
};

global.disable_field = function (selector) {
  $(selector).prop("disabled", true);
};

global.enable_field = function (selector) {
  $(selector).prop("disabled", false);
};