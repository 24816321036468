global.initProcedureLocationMap = function () {
  var x = parseFloat($('#x').html());
  var y = parseFloat($('#y').html());
  var markerPosition = {
    lat: '',
    lng: ''
  };

  if (x === '' || y === '') {
    return;
  }

  var latLongCoordinates = toLatLon(x, y, 14, "R");
  markerPosition.lat = latLongCoordinates.latitude;
  markerPosition.lng = latLongCoordinates.longitude;
  var map = new google.maps.Map(
    document.getElementById('map'), { zoom: 15, center: markerPosition });
  var marker = new google.maps.Marker({ position: markerPosition, map: map });
}