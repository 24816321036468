import { Controller } from "@hotwired/stimulus";

export default class Toast extends Controller {
  static values = { kind: String, text: String, delay: Number };

  connect = () => {
    setTimeout(() => {
      this.showToast();
    }, (this.delayValue || 0) * 1000);
  };

  showToast = () => {
    Toastify({
      text: this.textValue,
      duration: this.kindValue == 'danger' ? 16000 : 8000,
      close: true,
      gravity: "bottom",
      positionLeft: false,
      className: (`rounded alert alert-${this.kindValue}`)
    }).showToast();
  };
}
