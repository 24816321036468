$(document).on('turbo:load', function () {
  var boundary = $(window);
  var target = $(".sticky-button");
  if (target.length == 0) return;

  var targetTop = target.offset().top;
  var stick = function (e) {
    var scrollTop = boundary.scrollTop();
    if (scrollTop >= targetTop) {
      target.addClass("sticky");
    } else {
      target.removeClass("sticky");
    }
  };

  boundary.on("scroll", stick);
});