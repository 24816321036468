import $ from 'jquery';
import { Controller } from "@hotwired/stimulus";

export default class LocationForm extends Controller {
  static targets = ["stateField", "cityField"];

  connect() {
    this.listenForStateChanges();
  }

  listenForStateChanges = () => {
    $(this.stateFieldTarget).on('change', this.getNewCityOptions);
  };

  getNewCityOptions = () => {
    const stateName = this.stateFieldTarget.value;
    $.ajax({
      url: `/address_codes/cities`,
      type: 'GET',
      data: {
        'state': stateName
      },
      dataType: 'json',
      success: this.updateCityOptions,
      error: function (_request, error) {
        console.error(error);
      }
    });
  };

  updateCityOptions = (citiesData) => {
    const jqCitiesTarget = $(this.cityFieldTarget);
    jqCitiesTarget.prop("disabled", true);
    jqCitiesTarget.find('option').remove();
    jqCitiesTarget.append('<option value="" selected>Selecciona una Ciudad</option>');
    $.each(citiesData, function (_key, value) {
      jqCitiesTarget.append(`<option value='${value.name}'>${value.name}</option>`);
    });
    jqCitiesTarget.prop("disabled", false);
  };
}
