import { Controller } from "@hotwired/stimulus";

export default class DocumentForm extends Controller {
  static targets = ["documentPath", "documentName"];

  static progressBarKlasses = {
    selected: "progress-bar bg-secondary",
    started: "progress-bar progress-bar-striped progress-bar-animated bg-info",
    progressed: "progress-bar progress-bar-striped progress-bar-animated bg-info",
    finished: "progress-bar progress-bar-striped bg-success",
    error: "progress-bar progress-bar-striped bg-danger",
  };

  connect = () => {
    this.onDocumentSelect();
  };

  updateProgressBar = (step, progress) => {
    let documentPath = this.documentPathTarget.value;
    let documentName = documentPath.split('\\').at(-1);
    this.documentNameTarget.innerHTML = `
      <div class="progress">
        <div class="${this.constructor.progressBarKlasses[step]}" style="width: ${progress}%">
          <span>${documentName}</span>
        </div>
      </div>
    `;
  };

  notifyUploadWillBegin = () => {
    Toastify({
      text: "Subiendo documento...",
      duration: 6000,
      close: true,
      gravity: "bottom",
      positionLeft: false,
      className: "rounded alert alert-secondary"
    }).showToast();
  };

  onDocumentSelect = () => {
    this.documentPathTarget.addEventListener('change', (_event) => {
      this.notifyUploadWillBegin();
      this.updateProgressBar('selected', 100);
      // temporarily turning off direct uploads, until this issue is resolved: https://github.com/hotwired/turbo/issues/243
      if (this.documentPathTarget.dataset.directUploadUrl) {
        this.prepareDocumentUpload();
      }
    });
  };

  prepareDocumentUpload = () => {
    this.onUploadStarted();
    this.onUploadProgress();
    this.onUploadEnd();
    this.onUploadError();
  };

  onUploadStarted = () => {
    this.element.addEventListener("direct-upload:initialize", (_event) => {
      this.updateProgressBar('started', 0);
    });
  };

  onUploadProgress = () => {
    this.element.addEventListener("direct-upload:progress", (event) => {
      var progress = event.detail.progress;
      this.updateProgressBar('progressed', progress);
    });
  };

  onUploadEnd = () => {
    this.element.addEventListener("direct-upload:end", (_event) => {
      this.updateProgressBar('finished', progress);
    });
  };

  onUploadError = () => {
    this.element.addEventListener("direct-upload:error", (_event) => {
      this.updateProgressBar('error', progress);
    });
  };
}
