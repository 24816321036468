global.addLocationToTable = (locationFields) => {
  if (locationFields.length === 0) {
    return;
  }

  var name = $(locationFields).find(locationFieldsSelectors.NAME).val() || '-';
  var street = $(locationFields).find(locationFieldsSelectors.STREET).val();
  var number = $(locationFields).find(locationFieldsSelectors.NUMBER).val();
  var neighborhood = $(locationFields).find(locationFieldsSelectors.NEIGHBORHOOD).val();
  var full_address = '-';
  if (street || number || neighborhood) {
    full_address = `${street} ${number} ${neighborhood}` || '-';
  }
  var city = $(locationFields).find(locationFieldsSelectors.CITY).val() || '-';

  var table = document.getElementById(LOCATIONS_TABLE_ID);
  var row = table.insertRow(table.rows.length);
  var cell1 = row.insertCell(0); // name
  var cell2 = row.insertCell(1); // address
  var cell3 = row.insertCell(2); // city
  var actionsCell = row.insertCell(3); // actions
  var removeLink = document.createElement('a');
  removeLink.innerHTML = 'Eliminar';
  removeLink.setAttribute("class", "remove-location text-danger");
  removeLink.setAttribute("href", "javascript:;");
  removeLink.addEventListener('click', function () {
    if (confirm('Desea eliminar esta sucursal?')) {
      $(locationFields).find('.remove_fields').click();
      $(this).parent().parent().remove();
    }
  });

  var editLink = document.createElement('a');
  editLink.innerHTML = 'Editar';
  editLink.setAttribute("class", "edit-location text-secondary");
  editLink.setAttribute("href", "javascript:;");
  editLink.addEventListener('click', function () {
    $(locationFields).show();
    $(this).parent().parent().remove();
  });

  cell1.style.fontWeight = "800";
  cell1.innerHTML = name;
  cell2.innerHTML = full_address;
  cell3.innerHTML = city;
  actionsCell.append(editLink);
  actionsCell.append(' | ');
  actionsCell.append(removeLink);
}