import bootbox from "bootbox";

global.handleNewLocations = () => {
  $(locationsFormSelectors.CONTAINER).on('cocoon:before-insert', (event, insertedItem) => {
    if (missingLocationReqFields() > 0) {
      event.preventDefault();
      return bootbox.alert({
        message: "Favor de llenar el nombre, estado y municipio antes de agregar nuevas sucursales",
        backdrop: true,
      });
    } else {
      addVisibleLocationsToTable();
      hideExistingLocations();
    }
  });
  $(locationsFormSelectors.ADD_NEW).data("association-insertion-method", 'append')
    .data("association-insertion-node", locationsFormSelectors.FORMS);
};
