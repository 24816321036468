global.initLocationsMap = function () {
  var markerPosition = {
    lat: parseFloat($('#y').html()),
    lng: parseFloat($('#x').html())
  };

  if (markerPosition.lat === '' || markerPosition.lng === '') {
    return;
  }
  var map = new google.maps.Map(
    document.getElementById('map'), { zoom: 15, center: markerPosition });
  var marker = new google.maps.Marker({ position: markerPosition, map: map });
}