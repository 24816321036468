$(document).on('turbo:load', function () {
  $('.auto-submit').change(function () {
    if (this.id.indexOf("erase") >= 0) {
      this.setAttribute('value', 'true');
    }
    this.form.submit();
  });
});

global.initializeDocumentFields = function (objectId) {
  $("[id^=document_REPLACABLE_ID]").each(function (_i, element) {
    var replaceble_id = $(element).attr('id');
    replaceble_id = replaceble_id.replace(/REPLACABLE_ID/g, objectId);
    $(element).attr('id', replaceble_id);
  });

  $("[for^=document_REPLACABLE_ID]").each(function (_i, element) {
    var replaceble_for = $(element).attr('for');
    replaceble_for = replaceble_for.replace(/REPLACABLE_ID/g, objectId);
    $(element).attr('for', replaceble_for);
  });
  onDocumentSelect(objectId);
};

global.onDocumentSelect = function (objectId) {
  $('#document_' + objectId + '_file').on('change', function (event) {
    var documentPath = event.target.value;
    var documentName = documentPath.split('\\');
    documentName = $(documentName).last();
    $('#document_' + objectId + '_name_container').html('<div class="progress"> <div class="progress-bar bg-secondary" style="width: 100%"><span>' + documentName[0] + '</span></div></div>');
    prepareDocumentUpload(objectId);
  });
};

global.prepareDocumentUpload = function (objectId) {
  onUploadStarted(objectId);
  onUploadProgress(objectId);
  onUploadEnd(objectId);
  onUploadError(objectId);
};

global.onUploadStarted = function (objectId) {
  $('#document_' + objectId + '_nested_fields').on('direct-upload:initialize', function (event) {
    var documentPath = event.target.value;
    var documentName = documentPath.split('\\');
    documentName = $(documentName).last();
    $('#document_' + objectId + '_name_container').html('<div class="progress"> <div class="progress-bar progress-bar-striped progress-bar-animated bg-info" style="width: 0%"><span> upload start </span></div></div>');
  });
};

global.onUploadProgress = function (objectId) {
  $('#document_' + objectId + '_nested_fields').on("direct-upload:progress", function (event) {
    var progress = event.detail.progress;
    var documentPath = event.target.value;
    var documentName = documentPath.split('\\');
    documentName = $(documentName).last();
    $('#document_' + objectId + '_name_container').html('<div class="progress"><div class="progress-bar progress-bar-striped progress-bar-animated bg-info" style="width: ' + progress + '%"><span> upload progress </span></div></div>');
  });
};

global.onUploadEnd = function (objectId) {
  $('#document_' + objectId + '_nested_fields').on("direct-upload:end", function (event) {
    var documentPath = event.target.value;
    var documentName = documentPath.split('\\');
    documentName = $(documentName).last();
    $('#document_' + objectId + '_name_container').html('<div class="progress"> <div class="progress-bar progress-bar-striped bg-success" style="width: 100%"><span> upload end </span></div></div>');
  });
};

global.onUploadError = function (objectId) {
  $('#document_' + objectId + '_nested_fields').on("direct-upload:error", function (event) {
    var documentPath = event.target.value;
    var documentName = documentPath.split('\\');
    documentName = $(documentName).last();
    $('#document_' + objectId + '_name_container').html('<div class="progress"> <div class="progress-bar progress-bar-striped bg-danger" style="width: 100%"><span> upload error </span></div></div>');
  });
};