global.hideExistingContacts = () => {
  existingContactsForms().hide();
};

global.addExistingContactsToTable = () => {
  existingContactsForms().each(function () {
    addContactToTable(this);
  });
};

global.existingContactsForms = () => {
  return $(contactsFormSelectors.FORMS).find(contactsFormSelectors.WRAPPER_CLASS);
};
