import "./contacts_form/handleSubmit";
import "./contacts_form/addContactToTable";
import "./contacts_form/handleNewContacts";
import "./contacts_form/missingReqFields";
import "./contacts_form/makeContactEditsCancelable";
import "./contacts_form/visibleContacts";
import "./contacts_form/handleExistingContacts";

global.CONTACTS_TABLE_ID = 'contacts-table';

global.contactsFormSelectors = {
    CONTAINER: '#contacts',
    FORMS: '#contacts-forms',
    ADD_NEW: '#contacts .add_fields',
    WRAPPER_CLASS: '.nested-fields'
};

global.contactFieldsSelectors = {
    NAME: '.contact-form-name',
    POSITION: '.contact-form-position',
    LEGAL_REPRESENTATIVE: '.contact-form-legal-representative:checked',
    LOCATION: '.contact-form-location',
    PHONE: '.contact-form-phone',
    EMAIL: '.contact-form-email'
};

$(document).on('turbo:load', function () {
    makeContactEditsCancelable();
    hideExistingContacts();
    addExistingContactsToTable();
    handleNewContacts();
    handleContactsSubmit();
});
