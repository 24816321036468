import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.css';
import { Controller } from "@hotwired/stimulus";

export default class Select2 extends Controller {
  connect() {
    this.initSelect2();
  }

  disconnect() {
    this.cleanup();
  }

  get isMultiple() {
    return this.element.multiple;
  }

  initSelect2 = () => {
    $(this.element).select2({
      closeOnSelect: this.isMultiple ? false : true,
      tags: this.isMultiple
    }).on("select2:select", this.triggerChangeOnRootElement);
  };

  cleanup = () => {
    $(this.element).select2('destroy');
  };

  triggerChangeOnRootElement = (event) => {
    const changeEvent = new Event('change');
    event.currentTarget.dispatchEvent(changeEvent);
  };
}
