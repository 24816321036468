import bootbox from "bootbox";

global.handleLocationsSubmit = () => {
  $("#locations-form").submit((e) => {
    e.preventDefault();
    if (missingLocationReqFields() > 0) {
      confirmIncompleteFormSubmit(e.target);
    } else {
      onConfirmCallback(e.target);
    }
  });
};

global.confirmIncompleteFormSubmit = (form) => {
  bootbox.confirm({
    message: 'Las sucursales que no tengan nombre no serán guardadas',
    title: "Aviso",
    callback: function (result) {
      if (result) {
        onConfirmCallback(form);
      }
      onCancelCallback();
    }
  });
};

global.onConfirmCallback = (form) => {
  return form.submit();
};

global.onCancelCallback = () => {
  $("[name='commit']")[0].disabled = false;
};