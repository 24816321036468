global.LOCATIONS_TABLE_ID = 'locations-table';

global.locationsFormSelectors = {
    CONTAINER: '#locations',
    FORMS: '#locations-forms',
    ADD_NEW: '#locations .add_fields',
    WRAPPER_CLASS: '.nested-fields'
};

global.locationFieldsSelectors = {
    NAME: '.location-form-name',
    STREET: '.location-form-street',
    NUMBER: '.location-form-number',
    NEIGHBORHOOD: '.location-form-neighborhood',
    CITY: '.location-form-city',
    STATE: '.location-form-state',
};

import "./locations_form/handleSubmit";
import "./locations_form/addLocationToTable";
import "./locations_form/handleNewLocations";
import "./locations_form/missingReqFields";
import "./locations_form/makeLocationEditsCancelable";
import "./locations_form/visibleLocations";
import "./locations_form/handleExistingLocations";

$(document).on('turbo:load', function () {
    makeLocationEditsCancelable();
    hideExistingLocations();
    addExistingLocationsToTable();
    handleNewLocations();
    handleLocationsSubmit();
});
