document.addEventListener("turbo:before-stream-render", (event) => {
  // Add a class to an element we are about to add to the page
  // as defined by its "data-stream-enter-class"
  if (event.target.firstElementChild instanceof HTMLTemplateElement) {
    let enterAnimationClass = event.target.templateContent.firstElementChild.dataset.streamEnterClass;
    if (enterAnimationClass) {
      event.target.templateElement.content.firstElementChild.classList.add(enterAnimationClass);
    }
  }

  // Add a class to an element we are about to remove from the page
  // as defined by its "data-stream-exit-class"
  let elementToRemove = document.getElementById(event.target.target);
  let streamExitClass = elementToRemove.dataset.streamExitClass;
  if (streamExitClass) {
    // Intercept the removal of the element
    event.preventDefault();
    elementToRemove.classList.add(streamExitClass);
    // Wait for its animation to end before removing the element
    elementToRemove.addEventListener("transitionend", function () {
      // event.target.performAction();
    });
  }
});