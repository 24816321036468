import { Controller } from "@hotwired/stimulus";
import flatpickr from 'flatpickr';

export default class DateTimeField extends Controller {
  static values = { enableTime: { type: Boolean, default: false } };

  connect() {
    this.initFlatpickr();
  }

  initFlatpickr = () => {
    flatpickr(this.element, {
      enableTime: this.enableTimeValue,
      allowInput: true,
      dateFormat: `Y-m-d${this.enableTimeValue ? " G:i K" : ""}`
    });
  };
}
