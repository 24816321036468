/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import "jquery";
import "bootstrap";
import "@nathanvda/cocoon";
import "@hotwired/turbo-rails";

import "channels";
import "styles/application";
import "controllers";

Rails.start();
ActiveStorage.start();
global.chartTheme = require("../vendor/javascript/dashkit/charts");
global.Toastify = require("../vendor/javascript/toastify");

const cache = {};

function importAll(r) {
  r.keys().forEach((key) => (cache[key] = r(key)));
}
importAll(require.context('../services/', true, /\.js$/));
importAll(require.context('../DEPRECATED/', true, /\.js$/));