global.hideExistingLocations = () => {
  existingLocationsForms().hide();
};

global.addExistingLocationsToTable = () => {
  existingLocationsForms().each(function () {
    addLocationToTable(this);
  });
};

global.existingLocationsForms = () => {
  return $(locationsFormSelectors.FORMS).find(locationsFormSelectors.WRAPPER_CLASS);
};
