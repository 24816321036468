import bootbox from "bootbox";
import $ from 'jquery';

var markerPosition = {
  lat: '',
  lng: ''
};

global.initMap = function () {
  if (markerPosition.lat === '' || markerPosition.lng === '') {
    return;
  }
  var map = new google.maps.Map(
    document.getElementById('map'), { zoom: 15, center: markerPosition });
  var marker = new google.maps.Marker({ position: markerPosition, map: map });
};

$(document).on('turbo:load', function () {
  $('.show-map').click(function (e) {
    var y = parseFloat($(this).parent().parent().find('.environmental-key-y input').val());
    var x = parseFloat($(this).parent().parent().find('.environmental-key-x input').val());
    var locationName = $(this).parent().parent().find('.environmental-key-location-name').html();
    if (isNaN(y) || isNaN(x)) {
      return bootbox.alert({
        message: "Favor de llenar las coordenadas antes de mostrar del mapa"
      });
    }
    $('#mapModal').modal();
    var latLongCoordinates = toLatLon(x, y, 14, "R");
    markerPosition.lat = latLongCoordinates.latitude;
    markerPosition.lng = latLongCoordinates.longitude;
    $("#locationName").html(locationName);
    initMap();
  });

  $('.show-location-map').click(function (e) {
    var y = parseFloat($(this).data('y'));
    var x = parseFloat($(this).data('x'));
    var locationName = $(this).data('name');
    if (isNaN(y) || isNaN(x)) {
      return;
    }
    var latLongCoordinates = toLatLon(x, y, 14, "R");
    markerPosition.lat = latLongCoordinates.latitude;
    markerPosition.lng = latLongCoordinates.longitude;
    $('#mapModal').modal();
    $("#locationName").html(locationName);
    initMap();
  });

});