import bootbox from "bootbox";

global.handleContactsSubmit = () => {
  $('#contacts-form').submit((e) => {
    e.preventDefault();
    if (missingLocationReqFields() > 0) {
      confirmIncompleteContactFormSubmit(e.target);
    } else {
      onConfirmContactCallback(e.target);
    }
  });
};

global.confirmIncompleteContactFormSubmit = (form) => {
  bootbox.confirm({
    message: 'Los contactos que no tengan nombre no serán guardados',
    title: "Aviso",
    callback: function (result) {
      if (result) {
        onConfirmContactCallback(form);
      }
      onCancelContactCallback();
    }
  });
};

global.onConfirmContactCallback = (form) => {
  return form.submit();
};

global.onCancelContactCallback = () => {
  $("[name='commit']")[0].disabled = false;
};