import { Controller } from "@hotwired/stimulus";
import Rails from '@rails/ujs';

export default class AutoSubmitForm extends Controller {
  static targets = ["submitTrigger"];

  connect = () => {
    this.listenForTriggers();
  };

  listenForTriggers = () => {
    this.submitTriggerTargets.map(triggerTarget => {
      triggerTarget.addEventListener("change", (_event) => {
        this.autoSubmitForm();
      });
    });
  };

  autoSubmitForm = () => {
    Rails.fire(this.element, 'submit');
  };
}
