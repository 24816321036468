global.toggleAddButton = function (document_type_id) {
  $('#NA-' + document_type_id).on('click', function () {
    if ($(this).is(':checked')) {
      $('#add-' + document_type_id).hide();
    } else {
      $('#add-' + document_type_id).show();
    }
  });
};

global.hidesAddButton = function (document_type_id) {
  if ($('#NA-' + document_type_id).is(':checked')) {
    $('#add-' + document_type_id).hide();
  }
};